/**
 * Takes a map of class names to conditions and returns a joined
 * string with all classes whose conditions were truthy.
 *
 * @param {{ [class: string]: boolean }} map - Map of class names to conditions.
 */
export default (map) => {
  const classes = [];

  for (const name in map) {
    if (map[name]) {
      classes.push(name);
    }
  }

  return classes.join(" ");
};
