import styles from "./ToolBar.module.css";
import React, { useState, useEffect } from "react";

import downloadSnapshot from "./utils/downloadSnapshot";
import globalState from "./globalState";

function ToolBarItem({ label, action }) {
  return (
    <div
      className={styles.item}
      onMouseDown={(e) => {
        e.preventDefault();
        action();
      }}
      onTouchStart={(e) => {
        e.preventDefault();
        action();
      }}
    >
      <span className={styles.itemLabel}>{label}</span>
    </div>
  );
}

export function ToolBar({ setLoading }) {
  const [snapshotting, setSnapshotting] = useState(false);

  useEffect(() => {
    setLoading(snapshotting);
  }, [snapshotting, setLoading]);

  return (
    <div
      data-html2canvas-ignore
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={styles.itemList}>
        {globalState.files.length > 0 && (
          <ToolBarItem
            label={snapshotting ? "Saving..." : "Snapshot"}
            disabled={snapshotting}
            action={() => {
              setSnapshotting(true);
              downloadSnapshot().then(() => {
                setSnapshotting(false);
                console.log("downloaded");
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
