import styles from "./Canvas.module.css";
import React, { Component } from "react";

import globalState from "./globalState";

import { ProgressModal } from "./ProgressModal";
import { FileInfo } from "./FileInfo";
import { FileBar } from "./FileBar";
import { ToolBar } from "./ToolBar";
import { CanvasItem } from "./CanvasItem";

export class Canvas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileIds: [],
      selectedId: null,
      hoveredId: null,
      focusedId: null, // Makes non-hovered items transparent to see the focused item better.
      loading: false,
    };

    this.onFileDrag = this._onFileDrag.bind(this);
    this.onFileDrop = this._onFileDrop.bind(this);
    this.onKeyDown = this._onKeyDown.bind(this);
    this.onPointerMove = this._onPointerMove.bind(this);
    this.setLoading = this._setLoading.bind(this);

    this.focusTimeout = null;

    this.__lastRender = null;
    this.__renderCount = 0;
  }

  componentDidMount() {
    window.addEventListener("dragover", this.onFileDrag);
    window.addEventListener("drop", this.onFileDrop);
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("mousemove", this.onPointerMove);
    window.addEventListener("touchmove", this.onPointerMove);
  }

  componentWillUnmount() {
    window.removeEventListener("dragover", this.onFileDrag);
    window.removeEventListener("drop", this.onFileDrop);
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("mousemove", this.onPointerMove);
    window.removeEventListener("touchmove", this.onPointerMove);
  }

  _onKeyDown(e) {
    const { selectedId } = this.state;

    if (selectedId != null) {
      switch (e.key.toLowerCase()) {
        case "backspace":
        case "delete":
          globalState.files = globalState.files.filter(
            (f) => f.id !== selectedId
          );

          this.setState({
            selectedId: null,
            fileIds: globalState.files.map((f) => f.id),
          });
          break;
        default:
          break;
      }
    }
  }

  _onPointerMove(e) {
    globalState.pointer = {
      x: e.clientX,
      y: e.clientY,
    };
  }

  _onFileDrag(e) {
    e.preventDefault();

    globalState.pointer = {
      x: e.clientX,
      y: e.clientY,
    };
  }

  _onFileDrop(e) {
    e.preventDefault();

    if (e.dataTransfer.files.length === 0) {
      return;
    }

    const { files } = globalState;

    this.setState({
      loading: true,
    });

    const total = e.dataTransfer.files.length;
    let additions = [];

    const done = () => {
      if (additions.length === total) {
        const f = [...files, ...additions];

        globalState.files = f;

        this.setState({
          fileIds: f.map((f) => f.id),
          selectedId: additions[0].id,
          loading: false,
        });
      }
    };

    let x = e.clientX;
    let y = e.clientY;
    let i = 0;

    for (const file of e.dataTransfer.files) {
      const index = i++;

      loadImage(file).then(({ data, meta }) => {
        const xOffset = meta.width / 2;
        const yOffset = meta.height / 2;

        additions.push({
          id: globalState.inc++,
          meta,
          data,
          transform: {
            x: x - xOffset + 30 * index,
            y: y - yOffset + 30 * index,
            scale: 0.5,
            rotate: 0,
            zindex: 0,
          },
        });

        done();
      });
    }
  }

  _setLoading(value) {
    this.setState({
      loading: value,
    });
  }

  setFocus(fileId, delay = 1200) {
    if (!this.focusTimeout) {
      this.focusTimeout = setTimeout(() => {
        this.setState({
          focusedId: fileId,
        });
        this.focusTimeout = null;
      }, delay);
    }
  }

  clearFocus() {
    if (this.focusTimeout) {
      clearTimeout(this.focusTimeout);
      this.focusTimeout = null;
    }
  }

  updateFileTransform(id, transform) {
    const { files } = globalState;
    const ordered = files.sort((a, b) =>
      a.transform.zindex < b.transform.zindex ? -1 : 1
    );

    for (let i = 0; i < ordered.length; i++) {
      const file = ordered[i];
      if (file.id === id) {
        ordered[i].transform = {
          ...(transform || ordered[i].transform),
          zindex: files.length,
        };
      } else {
        ordered[i].transform.zindex = i;
      }
    }
  }

  _markRender() {
    const rnow = Date.now();
    const rdiff = rnow - (this.__lastRender || rnow);

    this.__lastRender = rnow;
    this.__renderCount++;

    console.log(`[REACT RENDER] #${this.__renderCount} +${rdiff}ms`);
  }

  render() {
    // this._markRender();

    const { fileIds, selectedId, hoveredId, focusedId, loading } = this.state;

    return (
      <div
        className={styles.container}
        onClick={(e) => {
          this.setState({
            selectedId: null,
          });
        }}
      >
        {fileIds.length === 0 && (
          <div className={styles.emptyMessage}>
            <p>Drop an Image</p>
          </div>
        )}

        <div className={styles.items}>
          {fileIds.map((id) => (
            <CanvasItem
              key={id}
              fileId={id}
              isSelected={id === selectedId}
              isHovered={id === hoveredId}
              isFocusFaded={focusedId != null && focusedId !== id}
              onTransformChanged={(t) => this.updateFileTransform(id, t)}
              onMouseEnter={() => {
                this.setState({
                  hoveredId: id,
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  hoveredId: null,
                  focusedId: null,
                });
              }}
              onSelect={() => {
                this.setState({
                  selectedId: id,
                });
                this.updateFileTransform(id);
              }}
            />
          ))}
        </div>

        <ToolBar setLoading={this.setLoading} />

        <FileInfo fileId={selectedId} />
        <ProgressModal isLoading={loading} />
        <FileBar
          selectedId={selectedId}
          focusedId={focusedId}
          hoveredId={hoveredId}
          onHoverEnter={(id) => {
            this.setState({
              hoveredId: id,
            });
            this.setFocus(id);
          }}
          onHoverLeave={() => {
            this.setState({
              focusedId: null,
              hoveredId: null,
            });
            this.clearFocus();
          }}
          onSelect={(id) => {
            this.setState({
              selectedId: id,
            });
            this.updateFileTransform(id);
          }}
        />
      </div>
    );
  }
}

function loadImage(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;

      img.onload = function () {
        const meta = {
          name: file.name,
          size: file.size,
          type: file.type,
          width: this.width,
          height: this.height,
        };

        return resolve({ data: e.target.result, meta });
      };
    };
    reader.readAsDataURL(file);
  });
}
