import styles from "./ProgressModal.module.css";
import React from "react";

import withClasses from "./utils/withClasses";

export function ProgressModal({ isLoading, message }) {
  return (
    <div
      data-html2canvas-ignore
      className={withClasses({
        [styles.container]: true,
        [styles.visible]: isLoading,
      })}
    >
      <div className={styles.modal}>
        <div className={styles.spinner}>
          <div />
        </div>
        <span className={styles.message}>{message || "Working"}</span>
      </div>
    </div>
  );
}
