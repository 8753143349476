import styles from "./CanvasItem.module.css";
import React from "react";

import withClasses from "./utils/withClasses";
import globalState from "./globalState";

function getTransformString(transform) {
  const { x, y, scale, rotate } = transform;
  return `translate(${x}px, ${y}px) scale(${scale}) rotate(${rotate}deg)`;
}

const img = new Image();
img.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

export class CanvasItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragOffset: { x: 0, y: 0 },
      dragging: false,
    };

    this.fileId = props.fileId;
    this.updateQueued = false;
    this.lastUpdate = 0;
    this.element = React.createRef();

    this.focusTimeout = null;
  }

  componentDidMount() {
    const { fileId } = this.props;
    const file = globalState.files.find((f) => f.id === fileId);
    if (file) {
      this.queueUpdate(file.transform);
    }
  }

  queueUpdate(transform) {
    if (!this.updateQueued) {
      this.updateQueued = true;

      requestAnimationFrame(() => {
        const style = this.element.current.style;

        style.setProperty("--border-width", 2 * (1 / transform.scale) + "px");
        style.transform = getTransformString(transform);

        this.props.onTransformChanged(transform);
        this.updateQueued = false;
      });
    }
  }

  render() {
    const { dragOffset } = this.state;
    const {
      fileId,
      isSelected,
      isHovered,
      isFocusFaded,
      onSelect,
      onFocusEnter,
      onFocusLeave,
      onMouseEnter,
      onMouseLeave,
    } = this.props;
    const file = globalState.files.find((f) => f.id === fileId);

    return (
      <div
        ref={this.element}
        draggable={true}
        className={withClasses({
          [styles.container]: true,
          [styles.selected]: isSelected,
          [styles.hovered]: isHovered,
          [styles.faded]: isFocusFaded,
        })}
        style={{
          zIndex: file.transform.zindex,
          width: file.meta.width,
          height: file.meta.height,
          backgroundImage: `url(${file.data})`,
        }}
        onMouseEnter={(e) => {
          if (onMouseEnter) onMouseEnter();

          if (onFocusEnter && !this.focusTimeout) {
            this.focusTimeout = setTimeout(() => {
              onFocusEnter();
              this.focusTimeout = null;
            }, 1200);
          }
        }}
        onMouseLeave={(e) => {
          if (onMouseLeave) onMouseLeave();

          if (this.focusTimeout) {
            clearTimeout(this.focusTimeout);
            this.focusTimeout = null;
          }
          if (onFocusLeave) onFocusLeave();
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          if (onSelect) onSelect();
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
          if (onSelect) onSelect();
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onDragStart={(e) => {
          e.dataTransfer.setDragImage(img, 0, 0);
          e.dataTransfer.clearData();

          this.setState({
            dragging: true,
            dragOffset: {
              x: e.clientX - file.transform.x,
              y: e.clientY - file.transform.y,
            },
          });
        }}
        onDrag={(e) => {
          e.preventDefault();

          const { pointer } = globalState;

          const x = pointer.x - dragOffset.x;
          const y = pointer.y - dragOffset.y;

          this.queueUpdate({
            ...file.transform,
            x,
            y,
          });
        }}
        onDragEnd={(e) => {
          e.preventDefault();

          this.setState({
            dragging: false,
          });
        }}
        onWheel={(e) => {
          const { transform } = file;

          if (e.shiftKey) {
            let adjustment = e.deltaX > 0 ? 3 : -3;
            // let adjustment = e.deltaX * 0.04;

            let rotate = (transform.rotate += adjustment);

            if (rotate < 0) {
              rotate = 360 + rotate;
            }

            if (rotate > 360) {
              rotate = rotate - 360;
            }

            this.queueUpdate({ ...transform, rotate });
          } else {
            // let adjustment = e.deltaY * 0.005;

            let adjustment = transform.scale * 0.08;
            if (e.deltaY < 0) {
              adjustment *= -1;
            }

            const scale = Math.min(
              Math.max((transform.scale -= adjustment), 0.1),
              5
            );
            this.queueUpdate({ ...transform, scale });
          }
        }}
      >
        <div className={styles.border} data-html2canvas-ignore />
      </div>
    );
  }
}
