import styles from "./FileInfo.module.css";
import React, { useState, useEffect } from "react";

import globalState from "./globalState";
import withClasses from "./utils/withClasses";
import bytesToHuman from "./utils/bytesToHuman";

/**
 * Shows detailed file info for the selected item.
 */
export function FileInfo({ fileId }) {
  const [meta, setMeta] = useState({
    name: "blank.x",
    type: "blank",
    width: 0,
    height: 0,
    size: 0,
  });

  useEffect(() => {
    const file = globalState.files.find((f) => f.id === fileId);
    if (file) {
      setMeta(file.meta);
    }
  }, [fileId]);

  const nameParts = meta.name.split(/\./g);
  const ext = nameParts.pop();
  const main = nameParts.join(".");
  const hasLongName = main.length > 20;

  return (
    <div
      data-html2canvas-ignore
      className={withClasses({
        [styles.container]: true,
        [styles.visible]: fileId != null,
      })}
    >
      <div className={styles.panel}>
        <div className={styles.field}>
          <label>name</label>
          {hasLongName ? (
            <div
              className={withClasses({
                [styles.truncatedName]: true,
                [styles.value]: true,
              })}
              title={meta.name}
            >
              {main.slice(0, 20)}
              <span className={styles.truncatedMarker}>[...]</span>
              {"." + ext}
            </div>
          ) : (
            <span className={styles.value}>{meta.name}</span>
          )}
        </div>
        <div className={styles.field}>
          <label>resolution</label>
          <span className={styles.value}>
            {meta.width}&times;{meta.height}px
          </span>
        </div>
        <div className={styles.field}>
          <label>size</label>
          <span className={styles.value}>{bytesToHuman(meta.size)}</span>
        </div>
        <div className={styles.field}>
          <label>type</label>
          <span className={styles.value}>{meta.type}</span>
        </div>
      </div>
    </div>
  );
}
