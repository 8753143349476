import styles from "./FileBar.module.css";
import React from "react";

import globalState from "./globalState";
import withClasses from "./utils/withClasses";

export function FileBar(props) {
  const {
    selectedId,
    focusedId,
    hoveredId,
    onHoverEnter,
    onHoverLeave,
    onSelect,
  } = props;
  const { files } = globalState;

  return (
    <div
      data-html2canvas-ignore
      className={withClasses({
        [styles.container]: true,
      })}
      onClick={(e) => {
        // Prevent clicks from traveling up to Canvas and deselecting items.
        e.stopPropagation();
      }}
    >
      <div className={styles.list}>
        {files
          .sort((a, b) => (a.id < b.id ? -1 : 1))
          .map((file) => (
            <div
              key={file.id}
              className={withClasses({
                [styles.file]: true,
                [styles.selected]: file.id === selectedId,
                [styles.hovered]: file.id === hoveredId,
                [styles.faded]: focusedId != null && file.id !== focusedId,
              })}
              onMouseEnter={(e) => {
                if (onHoverEnter) {
                  onHoverEnter(file.id);
                }
              }}
              onMouseLeave={(e) => {
                if (onHoverLeave) {
                  onHoverLeave(file.id);
                }
              }}
              onClick={(e) => {
                e.preventDefault();
                if (onSelect) {
                  onSelect(file.id);
                }
              }}
            >
              <img src={file.data} alt={file.meta.name}></img>
            </div>
          ))}
      </div>
    </div>
  );
}
