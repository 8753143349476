import html2canvas from "html2canvas";

export default function (element = document.body) {
  const options = {
    imageTimeout: 0,
  };

  return html2canvas(element, options).then((canvas) => {
    const url = canvas.toDataURL();
    const link = document.createElement("a");

    link.download = "pinboard.png";
    link.href = url;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}
